const AdoptAShark = {
  WHAT_IS_ADOPT_A_SHARK: `The Adopt-A-Shark Program was created by Collins Orthodontics to
    provide free orthodontic care to children cancer patients. Giving
    back to the community is a priority at our clinic, and a need was
    found with children cancer survivors. Often their journey is quite
    costly for their family and orthodontic treatment is financially
    out of reach.`,
  WHAT_IS_ADOPT_A_SHARK_ADD: `The Adopt-A-Shark Program makes orthodontic treatment possible and
    provides beautiful smiles and confidence to children who have
    found the strength to persevere despite overwhelming obstacles.`,
  WHY_IS_ADOPT_A_SHARK: `Sharks are strong. Sharks are powerful. Sharks are fighters. The
    same can be said about the brave children who have battled cancer.
    Sharks have an incredible ability to heal from wounds and
    diseases, and children cancer survivors have done the same.`,
  HOW_IS_ADOPT_A_SHARK: `Collins Orthodontics accepts donations to supplement the cost of
    braces for children cancer survivors in our community. 100% of
    these donations are used to provide the highest quality of
    orthodontic treatment to the recipients. To be clear, these
    families don’t come to us and ask for help, rather they are
    hand-picked from the community or offered the treatment at no cost
    or reduced cost once they become patients. Obviously, the need far
    outweighs a single office’s ability to provide. So, in order to
    help the maximum number of children possible, those who would like
    to help can donate to the cause.`,
  HOW_IS_ADOPT_A_SHARK_ADD: `Any donation is accepted. As a token of our gratitude for your
    help, those who donate $30 or more get to adopt our Collins mascot
    shark. This adorable braced-up stuffed shark comes with an
    adoption certificate, a card for you to write a personalized note
    to a child cancer survivor, and all the gratitude we can muster
    for making another child’s dream come true. Families with children
    battling cancer have enough on their plate without having to worry
    about how they can pay for braces. Please come by the office to
    see how you can help give a shark a home and a cancer survivor a
    smile.`,
}

export default AdoptAShark
