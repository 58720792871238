import React from 'react'

// App components
import CustomLink from '../../components/CustomLink'
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'

// App content
import AdoptAShark from '../../content/contact-us/adopt-a-shark'
import Smile6 from '../../assets/patientPics/smile6.jpg'
import ApplyButton from '../../assets/ApplyButton.png'
import DonateButton from '../../assets/DonateButton.png'

// App utils
import { themeStyles, colors, metrics, presets } from '../../utils/theme'

const styles = {
  button: {
    maxWidth: '220px',
  },
  buttonImg: {
    margin: 0,
    ...themeStyles.shadow,
  },
}

class Page extends React.Component {
  render() {
    const adoptASharkWhat = AdoptAShark.WHAT_IS_ADOPT_A_SHARK
    const adoptASharkWhatAdd = AdoptAShark.WHAT_IS_ADOPT_A_SHARK_ADD
    const adoptASharkWhy = AdoptAShark.WHY_IS_ADOPT_A_SHARK
    const adoptASharkHow = AdoptAShark.HOW_IS_ADOPT_A_SHARK
    const adoptASharkHowAdd = AdoptAShark.HOW_IS_ADOPT_A_SHARK_ADD

    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <PageTitle title="Adopt a Shark" bannerKey={'SHARK'} />
          <div css={themeStyles.textPadding}>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '800px',
                margin: '0 auto',
              }}
            >
              <div
                css={{
                  position: 'relative',
                  overflow: 'hidden',
                  paddingTop: '56.25%',
                  width: '100%',
                }}
              >
                <iframe
                  css={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 0,
                  }}
                  src="https://www.youtube.com/embed/1aJtsoMKwIw"
                  frameBorder="0"
                ></iframe>
              </div>
            </div>
            <img
              src={Smile6}
              css={{
                [presets.Tablet]: {
                  float: 'right',
                  marginLeft: metrics.defaultMargin,
                  maxHeight: '650px',
                  maxWidth: '500px',
                },
                display: 'none',
                maxHeight: '400px',
                float: 'none',
                margin: '0 auto',
                marginBottom: metrics.defaultMargin,
              }}
            />
            <h1 css={themeStyles.blueText}>
              What is the Adopt-A-Shark Program?
            </h1>
            <p>{adoptASharkWhat}</p>

            <p>{adoptASharkWhatAdd}</p>

            <h1 css={themeStyles.blueText}>Why Sharks</h1>
            <p>{adoptASharkWhy}</p>

            <h1 css={themeStyles.blueText}>How You Can Help</h1>
            <p>{adoptASharkHow}</p>

            <p>{adoptASharkHowAdd}</p>
          </div>
        </div>
        <div
          css={{
            backgroundColor: colors.lightGrey,
            width: '100%',
            padding: '30px',
          }}
        >
          <div css={themeStyles.contentWidth}>
            <div
              css={
                (themeStyles.textPadding,
                {
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                })
              }
            >
              <CustomLink
                css={[
                  styles.button,
                  { [presets.Phablet]: { marginRight: '60px' } },
                ]}
                to="https://res.cloudinary.com/collins-ortho/image/upload/v1688341688/pdfs/AdoptASharkApplication.pdf"
              >
                <img src={ApplyButton} css={styles.buttonImg} />
              </CustomLink>
              <CustomLink
                href="https://shopcollinsortho.com/collections/adopt-a-shark-collection"
                css={styles.button}
              >
                <img src={DonateButton} css={styles.buttonImg} />
              </CustomLink>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Page
